import React from "react";
import PropTypes from "prop-types";

import "./scss/masterlayout.module.scss";

const MasterLayout = ({
  children,
  className,
  mainClassName,
  header,
  footer,
}) => {
  return (
    <div className={className}>
      {header ? header : null}
      <main className={mainClassName}>{children}</main>
      {footer ? footer : null}
    </div>
  );
};

MasterLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  mainClassName: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
};

export default MasterLayout;
