import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import MasterLayout from "../components/masterlayout";
import Seo from "../components/seo";

import {
  header,
  errorContainer,
  errorMessage,
  goHome,
  notFound,
} from "./scss/404.module.scss";

const NotFoundHeader = () => {
  const data = useStaticQuery(graphql`
    query NotFoundSiteTitleQuery {
      site {
        siteMetadata {
          title
          subTitle
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { title, subTitle },
    },
  } = data;
  return (
    <header className={header}>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
    </header>
  );
};

const NotFoundPage = () => (
  <MasterLayout className={notFound} header={<NotFoundHeader />}>
    <Seo title="Not found" noIndex />
    <div className={errorContainer}>
      <div className={errorMessage}>{"Nothing to do here"}</div>
      <Link to="/" className={goHome} rel="home">
        {"Take me home"}
      </Link>
    </div>
  </MasterLayout>
);

export default NotFoundPage;
